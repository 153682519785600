:root {
  --brand-color: #460e85;
  --brand-color-xl: #cba7f4;
  --brand-color-xxl: #52258523;
  --brand-color-dark: #2f2936;
  --shadow-primary: #000000b7;
  --text-border-color: #0096FF;
  --image-border-color: #FF3131;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  position: relative;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

/* Modern scrollbar styles */
/* * {
  scrollbar-color: var(--brand-color-xl) var(--brand-color-dark);
} */

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--brand-color-dark);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--brand-color-xl);
  border-radius: 10px;
  border: 2px solid var(--brand-color-dark);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--brand-color);
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-content-wrapper {
  height: 100%;
  background-color: var(--brand-color);
}

.page-content-wrapper.frame-view {
  height: unset;
  background-color: unset;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
}

.text-center h1 {
  margin-bottom: 20px;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000099;
  text-align: center;
}

.modal-main {
  position: fixed;
  background: white;
  min-width: 35rem;
  height: auto;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 1px 40px 5px black;
  padding: 1rem;
}

.modal-head {
  display: flex;
  justify-content: space-between;
}

.modal-close-btn {
  font-size: 24px;
  cursor: pointer;
  color: var(--brand-color-dark);
  transition: color 0.2s ease-in-out;
}

.modal-body {
  display: flex;
  flex: 1;
  padding: 10px 10px 0 10px;
}

.modal-error {
  display: flex;
  flex: 1;
  padding: 0 0 0 10px;
}

.warning {
  color: rgb(221, 119, 17);
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.text-field {
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  padding: 8px 16px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #1a1f36;
  outline: 0;
  border: 1px solid #ccc;
  transition: all .3s ease;
  background-color: #fff;
}

.error-field {
  border-color: red;
}

.modal.display-block {
  display: block;
}

.modal.display-none {
  display: none;
}

.btn-container {
  padding-top: 10px;
  margin-bottom: 10px;
}

.btn {
  margin-right: 10px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.page-button {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.page-heading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}

.test {
  width: '100%';
  height: '100%';
}

.dialog {
  position: fixed;
  background-color: white;
  padding: 10px;
  border-radius: 20px;

}

.dialog-bg {
  position: fixed;
  background-color: transparent;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
}

.dialog-bg.display-block {
  display: block;
}

.dialog-bg.display-none {
  display: none;
}

.image-container>div,
.png-view {
  width: 100% !important;
  height: 100% !important;
}

.image-container>div svg {
  width: 100% !important;
  height: 100% !important;
}

.image-container>div svg text {
  display: none;
}

.png-view img {
  width: 100%;
  height: auto;
}

.figma-press-text {
  display: inline-block;
  white-space: pre-wrap;
  align-items: center;
  overflow-y: auto;
  color: black;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow-y: hidden;
}

.figma-press-text-node {
  cursor: pointer;
  stroke: none;
  stroke-width: 1;
  transition: stroke 0.2s ease-in-out;
  rx: 4px; /* Small border radius */
  ry: 4px;
}

.figma-press-text-node:hover {
  stroke: var(--text-border-color);
  stroke-dasharray: 4 4; /* Dotted border */
}

.figma-press-image-node {
  fill: transparent;
  cursor: pointer;
  stroke: none;
  stroke-width: 2;
  transition: stroke 0.2s ease-in-out;
  rx: 4px; /* Small border radius */
  ry: 4px;
}

.figma-press-image-node:hover {
  stroke: var(--image-border-color);
}

foreignObject {
  cursor: pointer;
}

.action-btn {
  margin-right: 0;
}

/* PngView styles */
.text-el-overlay {
  position: absolute;
  visibility: hidden;
  background-color: transparent;
}

.text-el-overlay.updated {
  visibility: visible;
  color: black;
  background-color: white;
}

/* custom styles for disbaled elements - overriding bs */
.faded {
  background-color: #6c757da5;
  border-color: #6c757da5;
}

.faded:hover {
  background-color: #6c757da5;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-text {
  margin-right: 5px;
}

.frame-name-wrapper {
  text-align: left;
}

.pending-update-icon {
  font-size: 1.2rem;
  position: relative;
  left: -10px;
}

.list-group-item {
  transition: background-color 0.2s ease;
}

.list-group-item:hover {
  background-color: var(--brand-color-xxl);
}

.frame-name {
  cursor: pointer;
}

.form-label {
  width: 100%;
}

.credentials-view {
  grid-template-columns: auto;
  max-width: 300px;
  margin: 0 auto;
}

.credentials-view-full {
  grid-column: span 2;
}

.credentials-view>p {
  justify-self: self-start;
}

/* Login page */
.login-wrapper {
  background-color: var(--brand-color);
}

.login-container {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0 20px 2px var(--shadow-primary);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-container form>div {
  margin-bottom: 15px;
}

.login-container label {
  display: block;
  margin-bottom: 5px;
}

.login-container input {
  width: 100%;
  min-width: 40ch;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #0056b3;
}

/* navbar styles */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--brand-color-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  z-index: 1000;
  box-shadow: 0 0 15px 4px var(--shadow-primary);
}

.navbar-brand-wrapper {
  display: flex;
  align-items: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
  color: white;
}

.navbar-brand:hover {
  color: var(--brand-color-xl);
}

.brand-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.brand-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.navbar-user {
  display: flex;
  align-items: center;
}

.user-email {
  margin-right: 15px;
}

.logout-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0056b3;
}

.back-button {
  margin-left: 1rem;
  outline: none;
  background: none;
  border: none;
  color: white;
}

.back-btn-icn {
  font-size: 1.75rem;
}

.back-btn-icn:hover {
  color: var(--brand-color-xl);
}